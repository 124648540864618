// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
// @import '../vendors/owl-carousel2/owl.carousel';
 @import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    /*&::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }*/
}

/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/module-slideshow';
@import 'modules/module-header';
@import 'modules/module-soumission';



/*******************************************************************************
* ACCUEIL
*******************************************************************************/

#blocIntro {
    text-align: center;
    padding: 13% 2% 50px 2%;

    p {
        font-weight: 500;
        font-size: $font-size-24;
        line-height: 1.5;
    }
}


#blocBackground {
    padding: 100px 10%;
    background: url("../images/accueil_section02_bg.jpg");
    background-repeat: no-repeat;
    background-attachment : fixed;
    background-size: cover;

    &.qui-nous-sommes {
          padding: 50px 10%;
          background: url("../images/quinoussommes_section02_bg.jpg");
    }

    .liste {
        display: flex;
        padding-left: 2%;
        padding-bottom: 8%;

        .left-side {
            padding-right: 13%;
        }
    }

    h3 {
        color: #fff;
        font-weight: 700;
        font-size: $font-size-60;
        padding-bottom: 50px;
        &.title {
              font-size: $font-size-60;
              padding-bottom: 15px;
              padding-top: 25px;
        }
    }



    li {
        color: #fff;
        list-style: square;
        font-weight: 300;
        font-size: $font-size-30;
        margin-bottom: -30px;
        text-transform: initial;
    }

    p {
        color: #fff;
        font-weight: 300;
        font-size: $font-size-24;
        line-height: 1.5;
    }
}


#bloc50-50 {
    display: flex;
    padding: 75px 10%;
    background: url("../images/accueil_section03_bg.jpg") no-repeat;
    background-size: cover;

    .left-side, .right-side {
        display: flex;
        width: 50%;

        .texte {
            padding-left: 5%;
        }
    }

    p {
        color: #fff;
        line-height: 1.5;
        font-weight: 300;
        margin: 0;
        font-size: $font-size-24;
        width: 100%;
    }

    h4 {
        color: #0e6322;
        font-weight: 700;
        font-size: $font-size-36;
    }
}

/***********************************************************************
page qui nous sommes
*/
section.section-portraits {
      padding: 30px 0;
      div.container-fluid.row {
            margin: 0 auto;;
            padding: 0;
            max-width: 1300px;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: center;
      }

      div.box-description {
            width: 35%;
            margin: 0 5%;
            margin-bottom: 20px;
            @media screen and (max-width: $size-xs-max){
              width: 80%;
              max-width: 400px;
            }
      }
      img {width: 100%;}
      h3 {
            font-size: $font-size-36;
            color: $color-primary;
            text-transform: inherit;
      }
      h4 {
            font-size: $font-size-26;
            color: $color-primary;
            text-transform: inherit;
      }
      p {
            font-size: $font-size-24;
            color: $color-primary;
            line-height: 1;
            text-transform: inherit;
      }
}

/***********************************************************************
page bouleau
*/
section.section-fiche-technique {
      padding: 10px;
      div.main-wrapper {
            background-image: url('../images/bouleau_section02_bg.jpg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            padding: 0 0 20px 0;
            &.jaune {
              background-image: url('../images/bouleau_section03_bg.jpg');
            }
            &.tremble {
              background-image: url('../images/tremble_section02_bg.jpg');
            }
      }

      div.title-block {
            background-color: $color-primary;
            padding: 1rem 2rem;

            h3 {
                  font-size: $font-size-50;
                  text-transform: inherit;
                  color: $color-white;
                  padding: 0;
                  margin: 0;
            }
      }
      div.subtitle-block {
            background-color: $color-primary;
            padding: 10px;
            h4.subtitle {
                  font-size: $font-size-24;
                  color: $color-white;
                  padding: 0;
                  margin: 0;
            }
      }

      div.content-block {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: center;
            margin: 25px 0;
            div.left-side, div.right-side {
                 width: 50%;
            }
            @media screen and (max-width: $size-xs-max){
                  div.left-side, div.right-side {
                       width: 100%;
                  }
            }
      }

      .full-side {
            div.fiche-text {
                  margin: 0 15px;
            }
      }

      div.fiche-text {
            padding: 10px;
            margin: 0 30px;
            background-color: $color-white;
            box-shadow: 0 0 30px rgba(0, 0, 0, .5);
      }


      div.text-wrapper {
            padding: 20px 15px;
            p {
                  font-weight: 400;
                  line-height: 1.5;
                  text-transform: inherit;
                  padding-bottom: 1rem;
                  font-size: $font-size-20;
                  &.bold {
                        padding-bottom: 0;
                        font-weight: 700;
                  }
                  &.vert {
                        color: $color-primary;
                        font-size: $font-size-20;
                        font-weight: 700;
                  }
            }

            ul {
                  padding-left: 40px;
                  li {
                        line-height: 1.5;
                        padding-left: 15px;
                        padding-bottom: 10px;
                        font-size: $font-size-20;
                        text-transform: inherit;
                  }
                  &.flex-liste {
                        display: flex;
                        flex-wrap: wrap;
                        align-items: flex-start;
                        justify-content: center;
                        li {
                              width: 50%;
                              @media screen and (max-width: $size-xs-max){
                                    width: 100%;
                              }
                        }
                  }
            }

            div.img-wrapper {
                  display: flex;
                  align-items: flex-start;
                  justify-content: center;
                  flex-wrap: wrap;
                  div.echant-box {
                        width: 33%;
                        transition: .3s;
                        img {margin: auto;}
                        p {text-align: center;}
                        @media screen and (max-width: $size-xs-min){
                              width: 100%;
                              transition: .3s;
                        }
                  }
            }
            div.tableau {
                  transition: .3s;
                  img {
                        width: 100%;
                  }
                  &:hover {
                        opacity: .7;
                        transition: .3s;
                  }
            }
      }
}
/*******************************************************************************
* PAGE COORDONNEES
*******************************************************************************/
section.section-01-coordonnees {
      text-align: center;
      padding: 80px 0;
      i {
            padding-right: 15px;
      }
      h3 {
            font-size: $font-size-60;
            color: $color-primary;
      }
      p.address {
            font-size: $font-size-30;
            color: $color-primary;
            padding-bottom: 30px;
      }
      p.phone a {
            font-weight: 400;
            color: $color-primary;
            font-size: $font-size-30;
            line-height: 1.5;
            transition: .3s;
            &:hover, &:focus {
              color: $color-black;
              transition: .3s;
            }
      }

      @media screen and (max-width: $size-xs-min){
            h3 {
                  font-size:24px;
            }
            p.address {
                  font-size: 18px;
            }
            p.phone a {
                  font-size: 20px;
            }
      }



}
/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: 1880px) {
    #blocBackground p br {
        display: none;
    }
    #blocBackground li {
        margin-bottom: 30px;
        line-height: 1.25;
    }
}

@media screen and (max-width: 1550px) {
    nav.module-menu .menu-navbar .wrapper.right .main-menu {
        width: 130%;
    }
}

@media screen and (max-width: 1440px) {
    nav.module-menu .menu-navbar .wrapper.left {
        width: 20%;
    }
}

@media screen and (max-width: 1400px) {
    nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li > a {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
}

@media screen and (max-width: $size-md-max) {
    nav.module-menu .menu-navbar .wrapper.left {
        padding: 65px 0;
    }
    nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li > a {
        padding-left: 0.7rem;
        padding-right: 0.7rem;
    }
    nav.module-menu .menu-navbar .wrapper.right .main-menu-top {
        padding-right: 3.5%;
    }
    nav.module-menu .menu-navbar.nav-invisible {
        top: -110px;
    }
}

@media screen and (max-width: 1144px) {

    nav.module-menu .menu-navbar .wrapper.right .main-menu div, nav.module-menu .menu-navbar .wrapper.right .main-menu li.lang div {
        font-size: 0.7rem;
    }
}
@media screen and (max-width: 1024px) {
    .slideshow-wrap .caption {
        top: 40%;
        left: 60%;
    }
    #blocIntro {
        padding: 20% 2% 50px 2%;
    }
    section.section-burst-icons {
        padding: 25px 0 0 0;
    }

}

@media screen and (max-width: $size-sm-max) {

    #bloc50-50 {
        flex-direction: column;
    }
    #bloc50-50 .left-side, #bloc50-50 .right-side {
        width: 100%;
    }
    #bloc50-50 .left-side {
        padding-bottom: 15%;
    }
    nav.module-menu .menu-navbar.nav-visible {
        background: #1e682b;
    }
    nav.module-menu {
        padding-top: 100px;
    }
    nav.module-menu .menu-navbar .wrapper.left {
        padding: 52px 0;
        width: 30%;
    }
    .slideshow-wrap .caption {
        top: 30%;
        left: 50%;
    }
    #blocBackground {
        padding: 100px 7%;
    }
    section.section-burst-icons div.row div.icon-bursts div.row div.icon-burst {
        margin: 135px auto;
        z-index: 1;
    }
    section.section-burst-icons div.row div.icon-bursts div.row div.icon-burst a div.text-bloc {
        position: relative;
        bottom: 46px;
        background: #fff;
    }
    section.section-burst-icons {
        padding: 0;
    }
}


@media screen and (max-width: $size-xs-max) {
    nav.module-menu .menu-navbar .wrapper.left {
        width: 45%;
    }
    .slideshow-wrap .caption {
        top: 45%;
        left: 50%;
        width: 100%;
    }
    #blocBackground .liste {
        flex-direction: column;
    }
    #bloc50-50 {
        padding: 75px 5%;
    }
    #bloc50-50 .left-side {
        padding-bottom: 20%;
    }
    section.section-burst-icons div.row div.icon-bursts {
        bottom: 0;
    }
}
