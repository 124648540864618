.bursts {
    width: 100%;
    padding: 100px 0 0 0;

    .bursts-container {
        justify-content: space-evenly;
    }

    .bursts-container {
        @extend .flex;
        .burst {
            //flex: 1;
            position: relative;
            .text-container, .overlay {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
            }
            .text-container {
                padding: 1.25rem;
                .border {
                    border: none;
                    width: 100%;
                    height: 100%;
                    @extend .flex;
                    @extend .flex-column;
                    @extend .justify-center;
                    @extend .items-center;
                    transition: all 0.2s ease-out; // OUT effect
                }
            }

            .overlay {
                opacity: 0;
                transition: all 0.7s ease-out; // OUT effect
            }
            h3,h4 {
                color: $color-white;
                transition: all 0.2s ease-out; // OUT effect
            }
            h3 {
                @extend .font-2-normal;
                font-size: 4vw;
                font-weight: 600;
                text-transform: none;
            }
            h4 {
                @extend .font-1-normal;
                font-size: 2.5vw;
            }
        }
        a:hover .burst {
            .overlay {
                opacity: 1;
                transition: all 0.3s ease; // IN effect
            }
            h3, h4 {
                color: $color-secondary;
                transition: all 0.3s ease; // IN effect

            }
            .border {
                //border-color: $color-secondary;
                transition: all 0.3s ease; // IN effect
            }
        }
    }
}
@media screen and (max-width: $size-slider-breakpoint) {
    .bursts {
        .bursts-container {
            flex-direction: column;
            .burst {
                h3 {
                    font-size: 2rem;
                }
                h4 {
                    font-size: 1rem;
                }
            }
        }
    }
}


section.section-burst-icons {
    padding: 100px 0 0 0;
    background: #fff;

    div.row {
        div.icon-bursts {
            display: flex;
            justify-content: center;
            align-items: center;
            transition: .5s;

            div.row {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                transition: .5s;
                width: 90%;

                ////Unique burtst
                div.icon-burst {
                    height: 100px;
                    @media screen and (max-width: 991px) {
                        transition: .5s;
                        height: auto;
                        margin: 50px auto;
                        &:nth-child(2){
                          border-right: none;
                          border-left: none;
                        }
                    }


                    div.element-burst {
                        background: #fff;
                        width: 100%;
                        position: relative;
                        transition: .5s;
                        &:hover {
                            transition: .5s;
                            div.content-img {
                              img {
                                transition: .5s;
                                transform: rotate(45deg);
                              }
                            }
                        }

                    }

                    a {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-around;
                        align-items: center;
                        div.content-img {
                            position: absolute;
                            bottom: 0;
                            width: 100%;
                            z-index: -1;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            transform: translateY(50%);
                            img {
                              transition: .5s;
                            }
                        }

                        div.text-bloc {
                            z-index: 1;
                            width: 100%;
                            height: 100px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-direction: column;
                            h3 {
                                color: #8f5f37;
                                font-weight: 700;
                                font-size: 1.5vw;
                                text-align: center;
                                text-transform: uppercase;
                                margin: 15px;
                                transition: .5s;
                                @media screen and (max-width: $size-sm-max){
                                  font-size: 20px;
                                  transition: .5s;
                                }
                                @media screen and (min-width: 1920px) {
                                  font-size: 30px;
                                  transition: .5s;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}